var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("img", {
      style: "width: " + _vm.size + "px;",
      attrs: {
        src: require("@/assets/images/icons/file-icons/" +
          _vm.extension +
          ".png"),
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }